import { Navigation } from './navigation';
import { LanguageSwitcher } from './languageSwitcher';
import { LanguageSwitcherBase } from '../languageSwitcher';
import { AnnouncementBanner } from '../announcementBanner';
import { NavAnalytics } from '../../../../../../_scripts/genericModules/navAnalytics';

export class Header {
  constructor(element) {
    this.container = element;
    this.analytics = new NavAnalytics(element, "header", "a,button");
    this.navigation = new Navigation(element);
    this.mobileMenuButton = this.container.querySelector('[data-mobile-menu-button]');
    this.logo = element.querySelector('[data-logo]');
    this.offsets = {};
    this.events = {
      offsetChange: [],
      heightChange: [],
    };
    this.mobileLanguages = new LanguageSwitcherBase(element.querySelector('[data-mobile-lang]'));
    this.init();
    this.updateHeaderSize();
  }

  init() {

    const banner = document.getElementById("announcement-banner");
  
    if (banner) {
      this.announcementBanner = new AnnouncementBanner(banner, this.container);
    }

    const languageSwitchButton = this.container.querySelector('[data-locale-button]');
    const languageSwitchDrop = this.container.querySelector('[data-locale-switcher]');

    if (languageSwitchButton && languageSwitchDrop) {
      this.languageSwitcher = new LanguageSwitcher(languageSwitchButton, languageSwitchDrop);
    }
    this.mobileMenuButton.addEventListener('click', () => this.toggleMobileMenu());
    window.addEventListener('resize', () => this.adjustForDeviceWidth());
    window.addEventListener('resize', () => this.updateHeaderSize());
    this.adjustForDeviceWidth();
  }

  callEvent(name, ...args) {
    if (this.events[name]) {
      this.events[name].forEach(callback => callback(...args));
    }
  }

  setBodyProperty(name, value, onUpdated) {
    if (document.body.style.getPropertyValue(name) !== value) {
      document.body.style.setProperty(name, value);
      onUpdated();
    }
  }

  headerOffsetChange(name, offset) {
    this.offsets[name] = offset;
    this.updateOffset();
  }

  get offsetHeight() {
    return this.container.offsetHeight + this.container.offsetTop + 16;
  }

  setHeightOnBody() {
    this.setBodyProperty('--header-height', `${this.offsetHeight}px`, () => {
      this.callEvent('heightChange', this.container.offsetHeight);
    });
  }

  updateHeaderSize() {
    this.setHeightOnBody();
  }

  updateOffset() {
    this.top = Object.values(this.offsets).reduce((a, b) => a + b, 0);
  }

  getAvailableSubNavHeight() {
    const logoRect = this.logo.getBoundingClientRect();
    const styles = getComputedStyle(this.container);
    const padding = styles.getPropertyValue('--header-padding');
    const margin = styles.getPropertyValue('--header-margin');
    return (
      window.innerHeight -
      (logoRect.top + logoRect.height + parseInt(padding, 10) + parseInt(margin, 10))
    );
  }

  set mobileMenuOpen(value) {
    if (value) {
      this.navigation.show('main');
      this.container.classList.add('header--mobile');
      document.body.classList.add('no-scroll');
    } else {
      this.navigation.hide();
      this.container.classList.remove('header--mobile');
      document.body.classList.remove('no-scroll');
    }

    this._mobileMenuOpen = value;
  }

  get mobileMenuOpen() {
    return this._mobileMenuOpen;
  }

  toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  adjustForDeviceWidth() {
    if (this.mobileMenuOpen && window.innerWidth >= 1280) {
      this.mobileMenuOpen = false;
      this.navigation.maxSubNavHeight = null;
    } else {
      this.navigation.maxSubNavHeight = this.getAvailableSubNavHeight();
    }
  }
}
