import { SimplifiedEventListener } from '../../../../../../_scripts/utils/simplifiedEventListener';
import { SubNavigationGroup } from './subNavigationGroup';

export class SubNavigation extends SimplifiedEventListener {
  constructor(element, callbacks = {}) {
    super(element);
    this.container = element;
    this.callbacks = callbacks;
    this.subNavigationGroups = Array.from(this.container.querySelectorAll('[data-sub-nav]')).map(
      el => new SubNavigationGroup(el),
    );
    this.current = null;
    this.init();
  }

  get open() {
    return this.current !== null;
  }

  init() {
    if (this.callbacks.mouseOver) {
      this.on('mouseenter', () => this.callbacks.mouseOver());
    }

    if (this.callbacks.mouseOut) {
      this.on('mouseleave', () => this.callbacks.mouseOut());
    }
  }

  set maxHeight(value) {
    this._maxHeight = value;
  }

  get maxHeight() {
    return this._maxHeight;
  }

  set height(value) {
    if (value > 0 && this.maxHeight && window.innerWidth < 1280) {
      value = this.maxHeight;
    }

    this.container.style.setProperty('--sub-nav-height', `${value}px`);
  }

  updateHeight() {
    if (this.current) {
      this.height = this.current.container.offsetHeight;
    }
  }

  show(key) {
    if (!this.current || this.current.key !== key) {
      const groupToShow = this.subNavigationGroups.filter(g => g.key === key).pop();

      groupToShow?.show();
      this.current?.hide();
      this.current = groupToShow;
    }

    this.updateHeight();
  }

  hide(key) {
    this.current?.hide();
    this.height = 0;
    this.current = null;
  }
}
