import { SimplifiedEventListener } from '../../../../../../_scripts/utils/simplifiedEventListener';
import { SubNavigationGroupAnimation } from './subNavigationGroupAnimation';
import { ButtonVideoPopup } from '../../../../../macros/buttonVideo/script';

export class SubNavigationGroup extends SimplifiedEventListener {
  constructor(element) {
    super(element);

    this.key = this.container.dataset.subNav;
    this.container = element;
    this.items = Array.from(this.container.querySelectorAll('[data-sub-nav-item]'));
    this.animationColumns = Array.from(
      this.container.querySelectorAll('[data-sub-nav-column]'),
    ).map(el => new SubNavigationGroupAnimation(el));
    this.animation = new SubNavigationGroupAnimation(this.container);
    this.media = [];
    this.visible = false;
    this.init();
  }

  init() {
    this.container.querySelectorAll("[data-button-video-pid]").forEach((media) => {
      try {
        this.media.push(new ButtonVideoPopup(media));
      } catch(e) {
        console.error(e);
      }
    });
  }

  get isMobile() {
    return window.innerWidth < 1280;
  }

  show() {
    if (this.isMobile) {
      this.animation.show();
    } else {
      this.animationColumns.forEach(col => col.show());
    }
    this.container.classList.remove('sub-nav--initial');
    this.visible = true;
  }

  hide() {
    if (!this.visible) {
      return;
    }

    if (this.isMobile) {
      this.animation.hide();
    } else {
      this.animationColumns.forEach(col => col.hide());
    }
    this.container.classList.add('sub-nav--initial');
    this.visible = false;
  }
}
